import { motion, useAnimation } from "framer-motion";
import React from "react";
import BgIcon from "../images/logo.svg";
import Navbar from "./Navbar";
import video1 from "../videos/bgvid1.mp4";
import { Typography } from "@material-tailwind/react";
import { scroller } from "react-scroll";

const Hero = ({ setLang, lang, setShowProductsPage }) => {
  const Contact = () => {
    scroller.scrollTo("contactSection", {
      smooth: true,
      offset: -50,
    });
  };
  const controls = useAnimation();
  React.useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);
  return (
    <motion.div
      className="relative h-screen"
      animate={controls}
      initial={{ opacity: 0, y: 50 }} // İlk durumu belirtin
      transition={{ duration: 0.5 }} // Animasyon süresini belirtin
    >
      <div className="absolute inset-0">
        <Navbar
          color={"white"}
          setLang={setLang}
          lang={lang}
          setShowProductsPage={setShowProductsPage}
        />
        <video
          autoPlay
          loop
          muted
          className="w-full h-full object-cover"
          src={video1}
          type="video/mp4"
        ></video>
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="absolute inset-0 flex gap-12 flex-col justify-center items-center text-center">
        <img
          src={BgIcon}
          alt="/"
          className="md:w-[600px] w-[200px] mx-auto pointer-events-none"
        />
        {lang !== "TR" ? (
          <Typography className="md:text-4xl text-sm font-semibold text-[#B39B60]">
            ENGINEERING
          </Typography>
        ) : null}

        <div className="flex flex-row gap-1 md:gap-4">
          <Typography className="md:text-4xl text-sm font-semibold text-[#B39B60]">
            {lang === "TR" ? "BAĞLANTI" : "CONNECTION"}
          </Typography>
          <Typography className="md:text-4xl text-sm font-thin  text-white">
            {lang === "TR" ? "EKİPMANLARI" : "EQUIPMENT"}
          </Typography>
        </div>

        <Typography className="md:text-2xl text-xs font-sans text-gray-500">
          {lang === "TR"
            ? '"KALİTE VE GÜVEN ARASINDAKİ BAĞINIZ"'
            : '"YOUR CONNECTION BETWEEN QUALITY AND TRUST"'}
        </Typography>
        <button
          onClick={Contact}
          className="bg-[#B39B60] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white transition duration-300 hover:bg-white hover:text-[#B39B60]"
        >
          {lang === "TR" ? "İletişime geç" : "Contact Us"}
        </button>
      </div>
    </motion.div>
  );
};

export default Hero;
