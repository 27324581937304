import React from "react";
import { Typography } from "@material-tailwind/react";
import Logo from "../images/logobrown.svg";

export default function Aboutus({ lang }) {
  return (
    <div className="gap-8 md:px-40 py-40 bg-white border-b md:justify-around items-center flex flex-col w-full">
      <Typography className="text-[#B39B60] text-4xl font-bold">
        {lang === "TR" ? "HAKKIMIZDA" : lang === "DE" ? "ÜBER UNS" : "ABOUT US"}
      </Typography>
      <div className="md:gap-20 w-full md:w-full flex flex-col md:flex-row">
        <img className="w-[200px] mx-auto py-4" src={Logo} alt="/" />
        <Typography
          variant="paragraph"
          className="text-black px-4 w-full" // Adjust max-width for responsiveness
        >
          {lang === "TR"
            ? "Afşar Mühendislik olarak yılların vermiş olduğu sektör tecrübesi ile  sektörün dinamiklerini ve değişkenliklerini yakından takip ediyoruz. Müşterilerimize verdiğimiz hizmetlerden aldığımız deneyimlerle, hizmet kalitemizi yükseltmek şirketimizin vazgeçilmez prensibidir. Müşteri memnuniyeti ve hizmet kalitesi merkezli çalışmalarımız; geniş ürün yelpazesi, ihtiyaç odaklı çözümlerle şekillenmektedir. Standart veya talebe göre tüm özel ölçülerde, en hızlı üretimi yapmaktayız.Müşterilerimizin göstermiş olduğu güvenle, yüksek sorumluluk bilincine sahip olarak çelikte sektörün çözüm ortağı olmak en büyük gayemizdir."
            : lang === "DE"
            ? "Als Afşar İngenieurwesen verfolgen wir mit unserer jahrelangen Erfahrung, wo genau die Dynamikund Variabilität der Branche liegt. Es ist ein unverzichtbarer Grundsatz unseres Unternehmens, mitden Erfahrungen, die wir durch Dienstleistungen für unsere Kunden gesammelt haben, unsere Servicequalität zu verbessern. Unsere auf Kundenzufriedenheit und Servicequalität ausgerichteten Bemühungen sind geprägt von einer breiten Produktpalette und bedarfsorientierten Lösungen. Wir sind spezialisiert auf Herstellungen, kundenspezifischer Abmessungen, entweder standardmäßig oder nach spezifischen Anforderungen, mit kürzesten Produktionszeiten. Unser oberstes Ziel ist es, der Lösungspartner der Stahlindustrie mit einem hohen Verantwortungsbewusstsein und dem Vertrauen unserer Kunden zu sein."
            : "Afşar Engineering closely monitors the dynamics and fluctuations of the industry with years of sector experience. Elevating the quality of our services based on the experiences gained from serving our customers is an indispensable principle of our company. Our customer satisfaction and service quality-focused efforts are shaped by a wide range of products and need-oriented solutions. We specialize in producing custom dimensions, either standard or according to specific requests, with the fastest production times. Being the solution partner of the steel industry with a high sense of responsibility, driven by the trust shown by our customers, is our ultimate goal."}
        </Typography>
      </div>
    </div>
  );
}
