import { motion, useAnimation } from "framer-motion";
import React from "react";
import Navbar from "./Navbar";
import product1 from "../images/products/product1.png";
import product2 from "../images/products/product2.png";
import product3 from "../images/products/product3.png";
import product4 from "../images/products/product4.png";
import product5 from "../images/products/product5.png";
import product6 from "../images/products/product6.png";
import product7 from "../images/products/product7.png";
import product8 from "../images/products/product8.png";
import ProductCard from "./ProductCard";

const ProductsPage = ({ setLang, lang, setShowProductsPage }) => {
  const controls = useAnimation();
  React.useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);
  const productsData = [
    {
      img: product1,
      text:
        lang === "TR"
          ? "AKS SAPLAMA"
          : lang === "DE"
          ? "Achsbolzen"
          : "PUNCTURE WOUND",
    },
    {
      img: product2,
      text:
        lang === "TR"
          ? "GİJON TİJ"
          : lang === "DE"
          ? "Gijon-Stange"
          : "GIJON ROD",
    },
    {
      img: product3,
      text:
        lang === "TR"
          ? "ÖZEL ÜRETİM ÜRÜN"
          : lang === "DE"
          ? "Spezielle Produktanfertigung"
          : "CUSTOM MANUFACTURING",
    },
    {
      img: product5,
      text: lang === "TR" ? "SOMUN" : lang === "DE" ? "Schraubenmutter" : "NUT",
    },

    {
      img: product4,

      text:
        lang === "TR"
          ? "ÖZEL ÜRETİM ÜRÜN"
          : lang === "DE"
          ? "Spezielle Produktanfertigung"
          : "CUSTOM MANUFACTURING",
    },
    {
      img: product6,
      text: lang === "TR" ? "J KANCA" : lang === "DE" ? "J-Haken" : "J HOOK",
    },
    {
      img: product7,
      text:
        lang === "TR"
          ? "U-V KARE BLOD"
          : lang === "DE"
          ? " U-V-Quadratischer Block"
          : "U-V SQUARE BLOCK",
    },
    {
      img: product8,
      text:
        lang === "TR"
          ? "JAKUZİ SAPLAMASI"
          : lang === "DE"
          ? "Jacuzzi-Befestigung"
          : "JACUZZI FASTENER",
    },
  ];

  return (
    <motion.div
      className="flex flex-col justify-start bg-[#B39B60]/20 min-h-screen overflow-y-auto"
      animate={controls}
      initial={{ opacity: 0, y: 50 }} // İlk durumu belirtin
      transition={{ duration: 0.5 }} // Animasyon süresini belirtin
    >
      <Navbar
        color={"dark"}
        setLang={setLang}
        lang={lang}
        setShowProductsPage={setShowProductsPage}
      />
      <div className="md:grid md:grid-cols-4 flex flex-col gap-4">
        {productsData.map((item, index) => (
          <ProductCard key={index} text={item.text} product={item.img} />
        ))}
      </div>
    </motion.div>
  );
};

export default ProductsPage;
