import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Hero from "./Components/Hero";
import Products from "./Components/Product";
import Error from "./Components/Error";
import Footer from "./Components/Footer";
import { Element } from "react-scroll";
import Aboutus from "./Components/Aboutus";
import Catolog from "./Components/Catolog";
import ProductsPage from "./Components/ProductsPage";
import { Helmet } from 'react-helmet-async';


export default function App() {
  const [lang, setLang] = React.useState("TR");
  const currentDate = new Date();
  const [showProducts, setShowProducts] = React.useState(false);
  const [showProductsPage, setShowProductsPage] = React.useState(false);
  const metaTitle =
    "Afşar Mühendislik - Bağlantı Ekipmanları ve Mühendislik Hizmetleri";
  const metaDescription =
    "Afşar Mühendislik, müşteri memnuniyetini en üst öncelik olarak benimseyen bir kuruluştur. Bağlantı ekipmanları konusunda uzmanlaşmış, kaliteli malzemeler kullanarak hizmet sunan bir mühendislik firması.";
  const metaKeywords =
    "afşar mühendislik, bağlantı ekipmanları, Konya'da mühendislik hizmeti, J kanka, U-V Kare Bold, Jakuzi Saplaması, Somun, Gijon Tij, Konya, özel üretim bağlantı modelleri";

  // Hero bileşeninin animasyonu için spring
  const heroSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
  });

  // Products bileşeninin animasyonu için spring
  const productsSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(0, 30px, 0)" },
    to: {
      opacity: showProducts ? 1 : 0,
      transform: showProducts
        ? "translate3d(0, 0, 0)"
        : "translate3d(0, 30px, 0)",
    },
  });

  // Scroll olayı tetiklendiğinde ürünleri yükle
  const handleScroll = () => {
    setShowProducts(true);
    window.removeEventListener("scroll", handleScroll); // Event listener'ı kaldır
  };

  // Function to handle the "Ürünler" item click
  const handleProductsClick = () => {
    setShowProductsPage(true);
  };

  useEffect(() => {
    // Component yüklendiğinde event listener ekle
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Component kaldırıldığında event listener'ı kaldır
    };
  }, []);

  return (
    <div className="main-page h-screen">
      <React.Fragment>
        {showProductsPage ? (
          <ProductsPage lang={lang} setLang={setLang} setShowProductsPage={setShowProductsPage} />
        ) : (
          <React.Fragment>
            <Element name="homeSection">
              <animated.div style={{ ...heroSpring, position: "relative" }}>
                <Hero
                  lang={lang}
                  setLang={setLang}
                  setShowProductsPage={setShowProductsPage}
                />
              </animated.div>
            </Element>

            <Element name="productsSection">
              <animated.div style={{ ...productsSpring, position: "relative" }}>
                <Products lang={lang} onClick={handleProductsClick} />
              </animated.div>
            </Element>

            <Element name="aboutSection">
              <animated.div style={{ ...productsSpring, position: "relative" }}>
                <Aboutus lang={lang} />
              </animated.div>
            </Element>

            <Element name="catolog">
              <animated.div style={{ ...productsSpring, position: "relative" }}>
                <Catolog lang={lang} />
              </animated.div>
            </Element>

            <Element name="contactSection">
              <animated.div style={{ ...productsSpring, position: "relative" }}>
                <Footer lang={lang} date={currentDate} />
              </animated.div>
            </Element>
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}
