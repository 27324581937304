import React from 'react';
import { useSwiper } from 'swiper/react';
import { ReactComponent as LeftArrow } from '../images/leftArrow.svg';
import { ReactComponent as RightArrow } from '../images/rightArrow.svg';

export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="flex justify-between items-center absolute top-1/3 p-5 w-full ">
      <button
        className="w-10 h-10 rounded-md  z-10"
        onClick={() => swiper.slidePrev()}
      >
        <LeftArrow />
      </button>
      <button
        className="w-10 h-10  rounded-md z-10 "
        onClick={() => swiper.slideNext()}
      >
        <RightArrow />
      </button>
    </div>
  );
};
