import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Typed from "react-typed";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import product1 from "../images/products/product1.png";
import product2 from "../images/products/product2.png";
import product3 from "../images/products/product3.png";
import product4 from "../images/products/product4.png";
import product5 from "../images/products/product5.png";
import product6 from "../images/products/product6.png";
import product7 from "../images/products/product7.png";
import product8 from "../images/products/product8.png";
import { SwiperNavButtons } from "./SwiperNavButton";
import ProductCard from "./ProductCard";
import productsText from "../images/products.svg";
const Products = ({ lang }) => {
  const productsData = [
    {
      img: product1,
      text:
        lang === "TR"
          ? "AKS SAPLAMA"
          : lang === "DE"
          ? "Achsbolzen"
          : "PUNCTURE WOUND",
    },
    {
      img: product2,
      text:
        lang === "TR"
          ? "GİJON TİJ"
          : lang === "DE"
          ? "Gijon-Stange"
          : "GIJON ROD",
    },
    {
      img: product3,
      text:
        lang === "TR"
          ? "ÖZEL ÜRETİM ÜRÜN"
          : lang === "DE"
          ? "Spezielle Produktanfertigung"
          : "CUSTOM MANUFACTURING",
    },
    {
      img: product5,
      text: lang === "TR" ? "SOMUN" : lang === "DE" ? "Schraubenmutter" : "NUT",
    },

    {
      img: product4,

      text:
        lang === "TR"
          ? "ÖZEL ÜRETİM ÜRÜN"
          : lang === "DE"
          ? "Spezielle Produktanfertigung"
          : "CUSTOM MANUFACTURING",
    },
    {
      img: product6,
      text: lang === "TR" ? "J KANCA" : lang === "DE" ? "J-Haken" : "J HOOK",
    },
    {
      img: product7,
      text:
        lang === "TR"
          ? "U-V KARE BLOD"
          : lang === "DE"
          ? " U-V-Quadratischer Block"
          : "U-V SQUARE BLOCK",
    },
    {
      img: product8,
      text:
        lang === "TR"
          ? "JAKUZİ SAPLAMASI"
          : lang === "DE"
          ? "Jacuzzi-Befestigung"
          : "JACUZZI FASTENER",
    },
  ];

  return (
    <div className=" w-full bg-[#B39B60]/20 py-20 px-4 border-b">
      <div className="flex flex-col py-12  justify-center items-center">
        <Typed
          className={
            lang === "TR"
              ? "md:text-6xl text-3xl text-[#B39B60] font-bold md:pl-4 pl-2"
              : "md:text-6xl text-2xl text-[#B39B60] font-bold md:pl-4 pl-1"
          }
          strings={
            lang === "TR"
              ? [
                  "AKS SAPLAMA",
                  "GİJON TİJ",
                  "SOMUN",
                  "J KANCA",
                  "U-V KARE BLOD",
                  "JAKUZİ SAPLAMASI",
                  "ÖZEL ÜRETİM",
                ]
              : lang === "DE"
              ? [
                  "ACHSBOLZEN",
                  "GIJON-STANGE",
                  "SCHRAUBENMUTTER",
                  "J-HAKEN",
                  "U-V-QUADRATISCHER BLOCK",
                  "JACUZZI-BEFESTIGUNG",
                  "SPEZIELLE PRODUKTANFERTIGUNG",
                ]
              : [
                  "PUNCTURE WOUND",
                  "GIJON ROD",
                  "NUT",
                  "J HOOK",
                  "U-V SQUARE BLOCK",
                  "JACUZZI FASTENER",
                  "CUSTOM MANUFACTURING",
                ]
          }
          typeSpeed={50}
          backSpeed={50}
          loop
        />
      </div>
      <div className="relative max-w-full flex justify-center items-center">
        <div className="absolute inset-0 w-full h-full  flex items-center justify-center">
          <img
            src={productsText}
            alt="ÜRÜNLER"
            className=" w-11/12 p-10 opacity-20"
          ></img>
        </div>
        <Swiper
          spaceBetween={40}
          slidesPerView={4}
          loop={true}
          grabCursor={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            200: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 1,
            },
            676: {
              slidesPerView: 2,
            },
            868: {
              slidesPerView: 2,
            },
            1250: {
              slidesPerView: 3,
            },
            1650: {
              slidesPerView: 4,
            },
            1950: {
              slidesPerView: 4,
            },
          }}
        >
          {productsData.map((item, index) => (
            <SwiperSlide key={index}>
              <ProductCard text={item.text} product={item.img} />
            </SwiperSlide>
          ))}
          <SwiperNavButtons />
        </Swiper>
      </div>
    </div>
  );
};

export default Products;
