import React from "react";
import {
  MapContainer as LeafletMap,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet"; // Import the Leaflet library
import "leaflet/dist/leaflet.css";
import logo from "../images/logobrown.svg";
const Map = () => {
  const position = [37.916049948643675, 32.526151870555653];

  // Import the default Leaflet marker icon
  const defaultIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [41, 41],
  });

  // Set the default icon for markers
  L.Marker.prototype.options.icon = defaultIcon;

  return (
    <LeafletMap
      attributionControl={false}
      center={position}
      zoom={14}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position}>
        <Popup>
          <img src={logo} alt="logo"></img>
        </Popup>
      </Marker>
    </LeafletMap>
  );
};

const MapComponent = () => {
  return <Map />;
};

export default MapComponent;
