import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import Logo from "../images/logobrown.svg";
import MapComponent from "./MapComponent"; // MapComponent bileşenini içe aktardık
import { AiFillMail } from "react-icons/ai";
import DLKSFT from "../images/dileksoft.svg";
import { Typography } from "@material-tailwind/react";

const Footer = ({ date, lang }) => {
  date = new Date().getFullYear();
  const onClick = () => {
    window.location.href = "https://user.wisitcard.com/necmettinzivlak";
  };
  const handleWhatsappMessage = () => {
    const apiUrl = "https://wa.me/905013214442"; // Telefon numarasını güncelleyin
    window.location.href = apiUrl;
  };
  return (
    <div className="max-w-full bg-white mx-auto py-10 just  px-4 text-gray-300">
      <div className="flex md:flex-row  items-center flex-col justify-center ">
        <div className="md:w-6/12 gap-4 px-8 flex flex-col w-full">
          <img className="w-[200px] mx-auto py-4" src={Logo} alt="/" />
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 text-black justify-between md:w-[75%] my-6">
            <div className="flex flex-row gap-2 items-center">
              <FaFacebook color="B39B60" size={30} />
              <Typography variant="small">Facebook</Typography>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaInstagram color="B39B60" size={30} />
              <Typography variant="small">Instagram</Typography>
            </div>
            <div
              className="flex flex-row gap-2 items-center"
              onClick={handleWhatsappMessage}
              style={{ cursor: "pointer" }}
            >
              <FaWhatsapp color="B39B60" size={30} />
              <Typography variant="small">Whatsapp</Typography>
            </div>
          </div>
          <div className="flex flex-col text-black gap-4 justify-between w-full md:w-2/5 my-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://maps.app.goo.gl/r62JxNcEJFpTxVV38"
              className="flex flex-row gap-2 items-center"
            >
              <FaMapMarkerAlt color="B39B60" size={24} />
              <Typography variant="small">
                {lang === "TR"
                  ? "Adres : Fevziçakmak Mah. 10502. Sk. No:53, 42050 Karatay/Konya"
                  : lang === "DE"
                  ? "Adresse : Fevziçakmak Mah. 10502. Sk. No:53, 42050 Karatay/Konya"
                  : "Address : Fevziçakmak Mah. 10502. Sk. No:53, 42050 Karatay/Konya"}
              </Typography>
            </a>
            <a
              href={`tel:+903323424728`}
              className="flex flex-row hover:rounded-lg hover:cursor-pointer gap-2 items-center"
            >
              <FaPhoneAlt color="B39B60" size={24} />
              <Typography variant="small">
                {lang === "TR"
                  ? "Telefon : 0 332 342 4728"
                  : lang === "DE"
                  ? "Telefon : +90 332 342 4728"
                  : "Phone : +90 332 342 4728"}
              </Typography>
            </a>
            <a
              href={`tel:+905013214442`}
              className="flex flex-row hover:rounded-lg hover:cursor-pointer gap-2 items-center"
            >
              <FaPhoneAlt color="B39B60" size={24} />
              <Typography variant="small">
                {lang === "TR"
                  ? "Telefon : 0501 321 4442"
                  : lang === "DE"
                  ? "Telefon : +90 501 321 4442"
                  : "Phone : +90 501 321 4442"}
              </Typography>
            </a>
            <a
              href={`tel:+905013214441`}
              className="flex flex-row hover:rounded-lg hover:cursor-pointer gap-2 items-center"
            >
              <FaPhoneAlt color="B39B60" size={24} />
              <Typography variant="small">
                {lang === "TR"
                  ? "Telefon : 0 501 321 4441"
                  : lang === "DE"
                  ? "Telefon : +90 501 321 4441"
                  : "Phone : +90 501 321 4441"}
              </Typography>
            </a>
            <a
              href={`mailto:info@afsarmuhendislik.com.tr`}
              className="flex flex-row gap-2 items-center"
            >
              <AiFillMail color="B39B60" size={24} />
              <Typography variant="small">
                Email : info@afsarmuhendislik.com.tr
              </Typography>
            </a>
          </div>
          <div className="flex flex flex-row-reverse justify-center md:w-[75%] my-6">
            <p className="flex  items-center text-md justify-center gap-4 flex-row">
              <Typography
                color="black"
                className="font-semibold"
                variant="small"
              >
                {lang === "TR"
                  ? "Geliştirici"
                  : lang === "DE"
                  ? "Entwickler"
                  : "Developer"}
              </Typography>
              <img
                src={DLKSFT}
                onClick={onClick}
                alt="DİLEK SOFT "
                className="w-32 cursor-pointer "
              />
            </p>
          </div>
        </div>
        <div className="w-full  md:w-5/12 shadow-2xl rounded-3xl h-full flex-col gap-4">
          <MapComponent />
        </div>
      </div>
      <p className="text-center  text-gray-500 text-sm mt-4">
        Copyright © {date} Necmettin Zıvlak
      </p>
    </div>
  );
};

export default Footer;
