import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Logo from "../images/logobrown.svg";
import { scroller } from "react-scroll";

const Navbar = ({ setLang, lang, color, setShowProductsPage }) => {
  const [nav, setNav] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("TR"); // Default language

  const handleSetActive = (section) => {
    setNav(false);

    // Use react-scroll's scroll functionality to smoothly scroll to the section
    if (section === "Home") {
      // Scroll to the Home section
      scroller.scrollTo("homeSection", {
        smooth: true,
        offset: -50, // Adjust the offset as needed
      });
    } else if (section === "About") {
      scroller.scrollTo("aboutSection", {
        smooth: true,
        offset: -50,
      });
    } else if (section === "Contact") {
      scroller.scrollTo("contactSection", {
        smooth: true,
        offset: -50,
      });
    } else if (section === "Catolog") {
      scroller.scrollTo("catolog", {
        smooth: true,
        offset: -50,
      });
    }
  };
  const handleNav = () => {
    setNav(!nav);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setLang(language);
  };

  return (
    <div
      className={` py-4 flex flex-row justify-around items-center h-24 max-w-screen w-full z-50 text-white ${
        color === "dark" ? "bg-black/80" : " fixed"
      }`}
    >
      <div>
        <img
          onClick={() => handleSetActive("Home")}
          className="md:w-[160px] w-[140px] mx-auto cursor-pointer"
          src={Logo}
          alt="/"
        />
      </div>
      <ul className="hidden md:flex">
        <li
          onClick={() => {
            handleSetActive("Home");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer`}
        >
          {lang === "TR" ? "Ana Sayfa" : lang === "DE" ? "Startseite" : "Home"}
        </li>

        <li
          onClick={() => setShowProductsPage(true)}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          }  font-semibold cursor-pointer`}
        >
          {lang === "TR" ? "Ürünlerimiz" : lang === "DE" ? "Produkte" : "Products"}
        </li>
        <li
          onClick={() => {
            handleSetActive("Catolog");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          }  font-semibold cursor-pointer`}
        >
          {lang === "TR" ? "Katolog" : lang === "DE" ? "Katalog" : "Catolog"}
        </li>
        <li
          onClick={() => {
            handleSetActive("About");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          }  font-semibold cursor-pointer`}
        >
          {lang === "TR" ? "Hakkımızda" : lang === "DE" ? " Über uns" : "About Us"}
        </li>
        <li
          onClick={() => {
            handleSetActive("Contact");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          }  font-semibold cursor-pointer`}
        >
          {lang === "TR" ? "İletişim" : "Contact"}
        </li>
      </ul>
      <div className="flex items-center">
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div className="relative mx-4">
          <select
            value={selectedLanguage}
            onChange={(e) => handleLanguageChange(e.target.value)}
            className="bg-transparent border-none text-gray-300 cursor-pointer px-4 py-2 rounded border hover:border-gray-400"
          >
            <option
              value="TR"
              className="bg-gray-100 text-black hover:bg-gray-200"
            >
              TR
            </option>
            <option
              value="EN"
              className="bg-gray-100 text-black hover:bg-gray-200"
            >
              EN
            </option>
            <option
              value="DE"
              className="bg-gray-100 text-black hover:bg-gray-200"
            >
              DE
            </option>
          </select>
        </div>
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "ease-in-out duration-500 fixed left-[-100%]"
        }
      >
        <li
          onClick={() => {
            handleSetActive("Home");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer border-b border-gray-600`}
        >
          {lang === "TR" ? "Ana Sayfa" : "Home"}
        </li>
        <li
          onClick={() => setShowProductsPage(true)}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer border-b border-gray-600`}
        >
          {lang === "TR" ? "Ürünlerimiz" : "Products"}
        </li>
        <li
          onClick={() => {
            handleSetActive("Catolog");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer border-b border-gray-600`}
        >
          {lang === "TR" ? "Katolog" : "Catolog"}
        </li>
        <li
          onClick={() => {
            handleSetActive("About");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer border-b border-gray-600`}
        >
          {lang === "TR" ? "Hakkımızda" : "About Us"}
        </li>
        <li
          onClick={() => {
            handleSetActive("Contact");
            setShowProductsPage(false);
          }}
          className={`p-4 ${
            color === "dark"
              ? "hover:text-white text-[#b39b60]"
              : "hover:text-[#B39B60] text-gray-300"
          } font-semibold cursor-pointer border-b border-gray-600`}
        >
          {lang === "TR" ? "İletişim" : "Contact"}
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
