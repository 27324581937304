import React from 'react'
import { Typography } from '@material-tailwind/react'

export default function ProductCard({ product, text}) {
  return (
    <div className='w-full flex  items-center justify-center' >
    <div className="w-60 md:h-[400px] h-[280px] md:w-96 shadow-xl bg-white md:my-12 my-8 rounded-lg flex flex-col items-center justify-center md:justify-start">
      <Typography  className='py-4 text-[#B39B60]' variant="h6">{text}</Typography>
      <div className="flex w-full h-full items-center hover:scale-105 duration-300 justify-center">
        <img src={product} alt="Product" className="md:h-[275px] h-[140px]" />
      </div>
    </div>
    </div>
  )
}
